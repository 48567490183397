<template>
  <div class="form">
    <div class="top">
      <h1 class="title">
        {{ uploaded && !isError ? $t('title_uploaded') : $t('title') }}
      </h1>
      <el-select
        v-model="language"
        @change="changeLanguage"
        :placeholder="$t('select_lang')"
        style="width:120px"
      >
        <el-option key="en" :label="$t('en')" value="en"> </el-option>
        <el-option key="zh-CN" :label="$t('zh_cn')" value="zh-CN"> </el-option>
        <el-option key="zh-CHT" :label="$t('zh_cht')" value="zh-CHT"></el-option>
        <el-option key="jp" :label="$t('jp')" value="jp">
        </el-option>
      </el-select>
    </div>
    <template v-if="!isError">
      <el-form
        ref="form"
        :model="form"
        :rules="rules"
        label-position="top"
        :disabled="uploaded"
      >
        <el-form-item :label="$t('order_id')" prop="code">
          <p class="mytip" v-if="!uploaded">{{ codeTip }}</p>
          <el-input
            v-model="form.code"
            :placeholder="$t('order_placeholder')"
          ></el-input>
        </el-form-item>
        <template v-if="lock">
          {{ lockMsg }}
        </template>
        <template v-else>
          <el-form-item
            :label="$t('e_mail')"
            prop="email"
            v-if="form.originCode != 'tb'"
          >
            <p class="mytip" v-if="!uploaded">{{ $t('email_tip') }}</p>
            <el-input
              v-model="form.email"
              :placeholder="$t('email_placeholder')"
            ></el-input>
          </el-form-item>
          <el-form-item
            :label="$t('taobao_account')"
            prop="remarks"
            v-if="form.originCode == 'tb'"
          >
            <el-input
              v-model="form.remarks"
              :placeholder="$t('taobao_placeholder')"
            ></el-input>
          </el-form-item>
          <el-form-item
            :label="$t('phone_number')"
            prop="phoneNumber"
            v-if="needPhone && (!uploaded || (uploaded && form.phoneNumber))"
          >
            <p class="mytip" v-if="!uploaded">{{ $t('phone_number_tip') }}</p>
            <el-input
              v-model="form.phoneNumber"
              :placeholder="$t('phone_number_placeholder')"
            ></el-input>
          </el-form-item>
          <el-form-item prop="imgs" :label="$t('upload_pictures')">
            <p class="mytip" v-if="!uploaded">{{ $t('imgs_tip') }}</p>
            <el-upload
              :file-list="form.imgs"
              accept="image/jpg,image/jpeg,image/png"
              multiple
              :limit="40"
              class="upload-demo"
              action="https://up.spedear.com/renren-fast/sys/oss/upload"
              :on-success="onUploadsuccess"
              :on-remove="onRemove"
              :on-change="onChange"
              :before-upload="beforeUpload"
              list-type="picture-card"
            >
              <i class="el-icon-plus"></i>
              <!--          <el-button>Upload pictures</el-button>-->
              <div slot="file" slot-scope="{ file }" class="imgBox">
                <img
                  v-if="file.response"
                  class="el-upload-list__item-thumbnail"
                  :src="file.response.url + '?x-oss-process=style/thumb_500'"
                  alt=""
                  :style="
                    `transform:rotate( ${90 *
                      (file.angle ? file.angle : 0)}deg)`
                  "
                />
                <span class="el-upload-list__item-actions">
                  <span
                    v-if="!uploaded"
                    class="el-upload-list__item-delete"
                    @click="editImg(file)"
                  >
                    <i class="el-icon-edit-outline"></i>
                  </span>
                </span>
              </div>

              <div
                slot="tip"
                class="el-upload__tip"
                v-if="form.imgs.length > 0"
              >
                {{ form.imgs.length }}
                {{ $t('uploaded_number_tip') }}
              </div>
            </el-upload>
            <el-button
              type="primary"
              @click="submit"
              v-if="form.imgs.length > 0 && !uploaded"
              >{{ $t('submit') }}
            </el-button>
          </el-form-item>
        </template>
      </el-form>
    </template>
    <template v-else>
      IS ERROR
    </template>

    <el-dialog
      class="actionDialogBox"
      :visible.sync="dialogVisible"
      width="30%"
    >
      <div class="actionDialog">
        <p class="action" @click="rotateLeft">{{ $t('img_rotate_left') }}</p>
        <p class="action" @click="rotateRight">{{ $t('img_rotate_right') }}</p>
        <p class="action" @click="removeImg">{{ $t('img_remove') }}</p>
      </div>
    </el-dialog>

    <el-dialog
      class="tipDalogWrapper"
      :visible.sync="tipDalogVisible"
      :title="$t('upload_tip_title')"
      :show-close="false"
      width="30%"
    >
      <div class="tipDialogBox">
        <div class="tipImgBox" v-for="item in $t('upload_nice')" :key="item">
          <img
            class="tipImg"
            :src="
              `https://internation.oss-cn-hongkong.aliyuncs.com/${language}/nice/${item}.png`
            "
          />
          <p class="text">{{ item }}</p>
        </div>
        <div class="tipImgBox" v-for="item in $t('upload_bad')" :key="item">
          <img
            class="tipImg"
            :src="
              `https://internation.oss-cn-hongkong.aliyuncs.com/${language}/bad/${item}.png`
            "
          />
          <p class="text">{{ item }}</p>
        </div>
      </div>
      <el-alert
        :title="$t('imgs_tip')"
        type="error"
        :closable="false"
        style="word-break: break-word ;"
        effect="dark"
      >
      </el-alert>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="tipDalogVisible = false">{{
          $t('i_konw')
        }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { compressFile } from '@/utils/compressFile'

export default {
  name: 'Index',
  props: {},
  components: {},
  data() {
    return {
      tipDalogVisible: false,
      editingImgIndex: -1,
      dialogVisible: false,
      language: 'en',
      lockMsg: '',
      lock: false,
      uploaded: false,
      codeTip: '',
      isError: false,
      needPhone: false,
      form: {
        originCode: 'asd',
        code: '',
        imgs: [],
        email: '',
        phoneNumber: '',
        remarks: '',
      },
      rules: {},
    }
  },
  methods: {
    rotateLeft() {
      let img = this.form.imgs[this.editingImgIndex]
      img.angle = img.angle && img.angle - 1 != -1 ? img.angle - 1 : 3
      this.form.imgs.splice(this.editingImgIndex, 1, img)
      this.dialogVisible = false
    },
    rotateRight() {
      let img = this.form.imgs[this.editingImgIndex]
      img.angle = img.angle && img.angle + 1 != 5 ? img.angle + 1 : 1
      this.form.imgs.splice(this.editingImgIndex, 1, img)
      this.dialogVisible = false
    },
    removeImg() {
      this.form.imgs.splice(this.editingImgIndex, 1)
      this.dialogVisible = false
    },
    editImg(file) {
      this.editingImgIndex = this.form.imgs.indexOf(file)
      this.dialogVisible = true
    },
    changeLanguage(value) {
      this.$i18n.locale = value
      document.title = this.$t('title')
      this.initRules()
      localStorage.setItem('lang', value)
    },
    initLang() {
      let map = {}
      let searchString = window.location.search.slice(1)
      searchString.split('&').forEach((search) => {
        let s = search.split('=')
        map[s[0]] = s[1]
      })
      if (map.lang == 'zh-cn') {
        map.lang = 'zh-CN'
      } else if (map.lang == 'zh-cht') {
        map.lang = 'zh-CHT'
      }else if (map.lang == 'jp') {
        map.lang = 'jp'
      }
      let lang = map.lang || localStorage.getItem('lang')
      if (lang) {
        this.language = lang
        this.changeLanguage(lang)
      }
      this.initRules()
    },
    initRules() {
      let rules = {
        code: [
          {
            required: true,
            message: this.$t('rule_code_not_empty'),
            trigger: 'blur',
          },
        ],
        imgs: [
          {
            required: true,
            message: this.$t('rule_imgs_not_empty'),
            trigger: 'blur',
          },
        ],
      }

      if (this.form.originCode != 'tb') {
        rules.email = [
          {
            required: true,
            message: this.$t('rule_email_not_empty'),
            trigger: 'blur',
          },
        ]
      } else {
        rules.remarks = [
          {
            required: true,
            message: this.$t('not_empty'),
            trigger: 'blur',
          },
        ]
      }
      if (this.form.originCode == 'etsysp') {
        this.codeTip = this.$t('etsy_order_tip')

        rules.code.push({
          validator: (rule, value, callback) => {
            if (!value.startsWith('1')) {
              callback(new Error(this.$t('etsy_order_error')))
            } else {
              callback()
            }
          },
          trigger: 'blur',
        })
      } else if (this.form.originCode == 'pinkoi') {
        this.codeTip = this.$t('pinkoi_order_tip')
      } else {
        this.codeTip = ''
      }
      console.log(rules)
      this.rules = rules
    },
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$confirm(
            this.$t('confirm_submit_tip'),
            this.$t('confirm_warning'),
            {
              cancelButtonText: this.$t('confirm_cancel'),
              confirmButtonText: this.$t('confirm_confirm'),
            }
          ).then(() => {
            // url: "https://up.spedear.com/renren-fast/generator/form/save",
            this.form.code = this.form.code.trim()
            this.$http({
              url: 'https://up.spedear.com/renren-fast/generator/form/save',
              method: 'post',
              data: this.$http.adornData({
                code: this.form.code,
                imgs: JSON.stringify(
                  this.form.imgs.map((item) => {
                    let url = item.response.url
                    if (item.angle) {
                      url += '?angle=' + item.angle
                    }
                    return url
                  })
                ),
                originCode: this.form.originCode,
                email: this.form.email,
                phoneNumber: this.form.phoneNumber,
              }),
            }).then(({ data }) => {
              if (data && data.code === 0) {
                this.$confirm(this.$t('confirm_have_received'), '', {
                  confirmButtonText: this.$t('confirm_nice'),
                  showCancelButton: false,
                }).then(() => {
                  window.open(
                    `/${this.form.originCode}/${this.form.code}`,
                    '_self'
                  )
                })
              } else {
                this.$confirm(this.$t('confirm_have_uploaded'), '', {
                  confirmButtonText: this.$t('confirm_cancel'),
                  showCancelButton: false,
                })
              }
            })
          })
        } else {
          return false
        }
      })
    },
    onRemove(file, fileList) {
      this.onChange(file, fileList)
    },
    onChange(file, fileList) {
      this.form.imgs = fileList
      this.$refs.form.validate()
    },
    onUploadsuccess(res, file, fileList) {
      this.onChange(file, fileList)
      document.documentElement.scrollTop = 99999999999 //IE浏览器
      window.pageYOffset = 9999999999999 //Safari浏览器
      document.body.scrollTop = 9999999999999 //opera，FireFox
    },
    beforeUpload(file) {
      let that = this
      return new Promise((resolve, reject) => {
        console.log(file)
        compressFile(file)
          .then((blob) => {
            // this.form.imgs.push(file)
            console.log(blob)
            resolve(blob)
          })
          .catch((err) => {
            let e = that.$t(err.message)
            that.$message({
              type: 'error',
              message: e,
              center: true,
            })
            reject()
          })
      })
    },
    requestForm() {
      let { code, originCode } = this.form

      this.$http({
        url:
          'https://up.spedear.com/renren-fast/generator/form/infoFromCodeAndOriginCode/' +
          code +
          '/' +
          originCode,
        method: 'get',
      }).then(({ data }) => {
        if (data.form) {
          this.form.email = data.form.email
          this.form.phoneNumber = data.form.phoneNumber
          this.form.remarks = data.form.remarks
          let imgs = JSON.parse(data.form.imgs)
          this.form.imgs = imgs.map((img, index) => {
            let splits = img.split('?')
            let url = splits[0]

            let item = {
              name: index,
              percentage: 100,
              status: 'success',
              url,
              response: {
                msg: 'success',
                code: 0,
                url,
              },
            }
            if (splits[1]) {
              let angle = splits[1].split('=')[1]
              item.angle = angle
            }

            return item
          })
          document.getElementsByClassName(
            'el-upload--picture-card'
          )[0].style.display = 'none'
        } else {
          if (data.lock) {
            this.lockMsg = this.$t('order_lockMsg_confidential')
          } else {
            this.lockMsg = this.$t('order_lockMsg_not_exist')
          }
          this.lock = true
        }
      })
    },
  },
  created() {
    let codeList = ['etsysp', 'pinkoi', 'tb']
    let splits = window.location.pathname.split('/')
    this.form.originCode = splits[1]
    console.log(splits)
    this.uploaded = splits[2] ? true : false
    this.tipDalogVisible = !this.uploaded
    if (this.uploaded) {
      this.form.code = splits[2]
      this.requestForm()
    }
    if (this.form.originCode && codeList.indexOf(this.form.originCode) > -1) {
      this.isError = false
      if (this.form.originCode == 'etsysp') {
        this.language = 'en'
        this.needPhone = true
      } else if (this.form.originCode == 'pinkoi') {
        this.language = 'zh-CHT'
      } else if (this.form.originCode == 'tb') {
        this.language = 'zh-CN'
      }
    } else {
      this.isError = true
    }
    this.$i18n.locale = this.language
    this.initLang()
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.tipDialogBox {
  display: flex;
  flex-wrap: wrap;
}
.tipImgBox {
  display: flex;
  flex-direction: column;
  /*margin: 6px;*/
  width: 33.333%;
  padding: 6px;
  box-sizing: border-box;
}
.tipImgBox .tipImg {
  width: 100%;
  height: auto;
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.tipImgBox .text {
  margin-top: 5px;
  text-align: center;
  /*font-weight: bold;*/
  word-break: break-word;
  font-size: 13px;
}
.imgBox {
  width: 100%;
  height: 100%;
}

.actionDialog {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
}

.actionDialog .action {
  color: #4fa6ff;
  padding: 10px 0;
  cursor: pointer;
  font-size: 22px;
}

.top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title {
  display: inline;
}

.form {
  /*padding: 1em;*/
}

.mytip {
  display: block;
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 0.5em;
}
</style>
