module.exports = {
  select_lang: '选择语言',
  title: '提交图片',
  title_uploaded: '提交成功',
  order_id: '订单号',
  order_placeholder: '请输入您的订单号',
  etsy_order_tip: '例如1857321481，它不是交易ID',
  pinkoi_order_tip: '例如201811282047rYUi',
  etsy_order_error: '它必须是以1开头的',
  order_lockMsg_confidential: '当前订单处于保密状态',
  order_lockMsg_not_exist: '当前订单不存在',
  phone_number: '手机号码',
  phone_number_placeholder: '手机号码不是必填的',
  phone_number_tip:
    '由于快递要求，如果您来自以下国家（奥地利、加拿大、中国、丹麦、新西兰、爱尔兰、日本），则需要提供您的手机号码',
  upload_pictures: '上传图片',
  e_mail: '电子邮箱',
  email_tip: '如果您的图片有问题，我们会通过电子邮箱与您联系',
  email_placeholder: '请输入您的电子邮箱',
  confirm_confirm: '提交',
  confirm_cancel: '取消',
  confirm_warning: '警告',
  confirm_submit_tip: '提交之后不能修改，确定提交吗',
  confirm_nice: '好的',
  confirm_have_received:
    '我们已经收到您的图片，如果没有问题，我们会尽快为您发货',
  confirm_have_uploaded: '这个订单已经提交过图片了',
  submit: '提交',
  uploaded_number_tip: '张已经上传',
  rule_code_not_empty: '订单号不能为空',
  rule_imgs_not_empty: '图片不能为空',
  rule_email_not_empty: '电子邮箱不能为空',
  img_rotate_left: '旋转-90°',
  img_rotate_right: '旋转90°',
  img_remove: '删除图片',
  imgs_tip: '如果您的图片的方向不是正确的，你可以点击图片进行调整',
  upload_tip_title: '上传图片建议',
  upload_bad: [
    '大合照',
    '光线不足',
    '聊天记录',
    '曝光过度',
    '文字太小',
    '照片模糊',
  ],
  upload_nice: ['半身照片', '大文字', '自拍照'],
  i_konw: '我知道了',
  'Failed to load the image.': '图片加载失败',
  not_empty: '不能为空',
  taobao_placeholder: '请输入淘宝账号',
  taobao_account: '淘宝账号',
}
