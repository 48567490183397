module.exports = {
  en: 'English',
  zh_cn: '简体中文',
  zh_cht: '繁體中文',
  jp: '日本語',
  select_lang: 'Select language',
  title: 'SUBMIT PICTURES',
  title_uploaded: 'SUBMIT SUCCESSFULLY',
  order_id: 'Order ID',
  order_placeholder: 'Please input the order ID',
  etsy_order_tip: "Such as 1857321481, It isn't Transaction ID",
  pinkoi_order_tip: 'Such as 201811282047rYUi',
  etsy_order_error: 'The order id must start with 1',
  order_lockMsg_confidential: 'The current order is in a confidential state',
  order_lockMsg_not_exist: 'The current order does not exist',
  phone_number: 'Phone number',
  phone_number_placeholder: 'Mobile phone number is not required',
  phone_number_tip:
    'Due to express delivery requirements, if you are from the following countries(Austria, Canada, China, Denmark, New Zealand, Ireland, Japan), your mobile phone number is required',
  upload_pictures: 'Upload pictures',
  e_mail: 'E-mail',
  email_tip:
    'If there is a problem with your picture, we will contact you via email',
  email_placeholder: 'Please input your email',
  confirm_confirm: 'confirm',
  confirm_cancel: 'cancel',
  confirm_warning: 'warning',
  confirm_submit_tip:
    'Cannot be modified after submission, are you sure to submit？',
  confirm_nice: 'nice!',
  confirm_have_received:
    'We have received your pictures, if there is no problem, we will make it for you soon.',
  confirm_have_uploaded: 'This order has been uploaded with photos',
  submit: 'Submit',
  uploaded_number_tip: 'has been uploaded',
  rule_code_not_empty: 'Order ID cannot be empty',
  rule_imgs_not_empty: 'Picture cannot be empty',
  rule_email_not_empty: 'email cannot be empty',
  img_rotate_left: 'rotate -90°',
  img_rotate_right: 'rotate 90°',
  img_remove: 'remove picture',
  imgs_tip:
    'If the orientation of your picture is not correct, you can click on the picture to adjust',
  upload_tip_title: 'Suggestions for uploading pictures',
  upload_bad: [
    'Group photo',
    'Too dark',
    'Chat record',
    'Overexposed',
    'Small text',
    'Photo blur',
  ],
  upload_nice: ['Half-length photo', 'HD graphics', 'Selfie'],
  i_konw: 'I know',
  not_empty: 'cannot be empty',
  taobao_placeholder: 'Please enter Taobao account number',
  taobao_account: 'Taobao account number',
}
