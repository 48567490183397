import Vue from 'vue'
import App from './App.vue'
import 'babel-polyfill'
import Es6Promise from 'es6-promise'
require('es6-promise').polyfill()
Es6Promise.polyfill()

import httpRequest from '@/utils/httpRequest' // api: https://github.com/axios/axios

import VueI18n from 'vue-i18n'        //引入vue-i18n
Vue.use(VueI18n);    //通过插件的形式挂载
const i18n = new VueI18n({
  locale: 'zh',    // 语言标识
  //this.$i18n.locale // 通过切换locale的值来实现语言切换
  messages: {
    'zh-CN': require('./common/lang/zh-CN'),   // 中文语言包
    'zh-CHT': require('./common/lang/zh-CHT'),   // 中文语言包
    'en': require('./common/lang/en'),    // 英文语言包
    'jp': require('./common/lang/jp')    // 日语
  }
})

import {Col,Card,Dialog,Button, Select,Option,Form, FormItem,Upload,Input,MessageBox,Message,Alert } from 'element-ui';
Vue.use(Col)
Vue.use(Card)
Vue.use(Dialog)
Vue.use(Button)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Upload)
Vue.use(Input)
Vue.use(Select)
Vue.use(Option)
Vue.use(Alert)
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$message = Message
Vue.prototype.$http = httpRequest // ajax请求方法

Vue.config.productionTip = false
new Vue({
  i18n,
  render: h => h(App),
}).$mount('#app')
