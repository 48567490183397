<template>
  <div id="app">
    <Index></Index>
  </div>
</template>

<script>
import Index from './components/Index.vue'

export default {
  name: 'App',
  components: {
    Index
  }
}
</script>

<style>
*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
body{
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*text-align: center;*/
  color: #2c3e50;
  /*max-width: 600px;*/
  margin: 0 auto;
  padding: 16px;
}
.el-upload-list__item-thumbnail{
  object-fit: contain;
}
.el-upload-list--picture-card .el-upload-list__item,.el-upload--picture-card{
  width: calc((100vw - 32px) / 3 - 13px) !important;
  height: calc((100vw - 32px) / 3 - 13px) !important;
  line-height:calc((100vw - 32px) / 3 - 13px) !important;
  margin: 4px;
}

.el-message-box{
  width: auto !important;
  max-width: 420px !important;
}
.el-form--label-top .el-form-item__label{
  font-weight: bold;
  padding-bottom: 0;
}
.el-icon-close-tip{
  display: none !important;
}
.el-dialog{
  margin-top: 50vh !important;
  transform: translateY(-50%) !important;
  min-width: 300px !important;

}
.tipDalogWrapper .el-dialog{
  min-width: 340px !important;

}
.tipDalogWrapper .el-dialog__body{
  padding: 10px 20px;
}
.tipDalogWrapper .el-alert__content{
  padding: 0;
}
</style>
