module.exports = {
  select_lang: '選擇語言',
  title: '提交圖片',
  title_uploaded: '提交成功',
  order_id: '訂單編碼',
  order_placeholder: '請輸入您的訂單編碼',
  etsy_order_tip: '例如1857321481，它不是交易ID',
  pinkoi_order_tip: '例如201811282047rYUi',
  etsy_order_error: '它必須是以1開頭的',
  order_lockMsg_confidential: '當前訂單處於保密狀態',
  order_lockMsg_not_exist: '當前訂單不存在',
  phone_number: '手機號碼',
  phone_number_placeholder: '手機號碼不是必填的',
  phone_number_tip:
    '由於快遞要求，如果您來自以下國家（奧地利、加拿大、中國、丹麥、新西蘭、愛爾蘭、日本），則需要提供您的手機號碼',
  upload_pictures: '上傳圖片',
  e_mail: '電子郵箱',
  email_tip: '如果您的圖片有問題，我們會通過電子郵件與您聯系',
  email_placeholder: '請輸入您的電子郵箱',
  confirm_confirm: '提交',
  confirm_cancel: '取消',
  confirm_warning: '警告',
  confirm_submit_tip: '提交之後不能修改，確定提交嗎',
  confirm_nice: '好的',
  confirm_have_received:
    '我們已經收到您的圖片，如果沒有問題，我們會盡快為您發貨',
  confirm_have_uploaded: '這個訂單已經提交過圖片了',
  submit: '提交',
  uploaded_number_tip: '張已經上傳',
  rule_code_not_empty: '訂單號不能為空',
  rule_imgs_not_empty: '圖片不能為空',
  rule_email_not_empty: '電子郵箱不能為空',
  img_rotate_left: '旋轉-90°',
  img_rotate_right: '旋轉90°',
  img_remove: '刪除圖片',
  imgs_tip: '如果您的圖片的方向不是正確的，妳可以點擊圖片進行調整',
  upload_tip_title: '上傳圖片建議',
  upload_bad: [
    '大合照',
    '光線不足',
    '聊天記錄',
    '曝光過度',
    '文字太小',
    '照片模糊',
  ],
  upload_nice: ['半身照片', '大文字', '自拍照'],
  i_konw: '我知道了',
  'Failed to load the image.': '圖片加載失敗',
  not_empty: '不能為空',
  taobao_placeholder: '請輸入淘寶賬號',
  taobao_account: '淘寶賬號',
}
