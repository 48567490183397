module.exports = {
  select_lang: '言語を選択してください',
  title: '画像を送信する',
  title_uploaded: '正常に送信されました',
  order_id: '注文番号',
  order_placeholder: '注文番号を入力してください',
  etsy_order_tip: 'たとえば、1857321481 はトランザクション ID ではありません。',
  pinkoi_order_tip: '例: 201811282047rYUi',
  etsy_order_error: '1 から始める必要があります',
  order_lockMsg_confidential: '現在の注文は機密扱いとなります',
  order_lockMsg_not_exist: '現在の注文は存在しません',
  phone_number: '電話番号',
  phone_number_placeholder: '携帯電話番号は不要です',
  phone_number_tip:
    '宅配便の要件により、次の国からお越しの場合は携帯電話番号が必要です (オーストリア、カナダ、中国、デンマーク、ニュージーランド、アイルランド、日本)',
  upload_pictures: '画像をアップロードする',
  e_mail: 'Eメール',
  email_tip: '画像に問題がある場合はメールでご連絡させていただきます',
  email_placeholder: 'あなたのメールアドレスを入力してください',
  confirm_confirm: '送信',
  confirm_cancel: 'キャンセル',
  confirm_warning: '警告する',
  confirm_submit_tip: '送信後に変更することはできません。送信してもよろしいですか?',
  confirm_nice: 'OK',
  confirm_have_received:
    '写真を受け取りました、問題がなければできるだけ早く発送させていただきます',
  confirm_have_uploaded: 'この注文はすでに写真を送信しています',
  submit: '送信',
  uploaded_number_tip: '張さんがアップロードしました',
  rule_code_not_empty: '注文番号を空にすることはできません',
  rule_imgs_not_empty: '画像を空にすることはできません',
  rule_email_not_empty: '電子メールを空にすることはできません',
  img_rotate_left: '-90°回転',
  img_rotate_right: '90°回転',
  img_remove: '画像を削除する',
  imgs_tip: '画像の向きが正しくない場合は、画像をクリックして調整できます',
  upload_tip_title: '画像の提案をアップロードする',
  upload_bad: [
    'ツーショット',
    '光不足',
	'チャット記録',
    '露出オーバー',
	'文字が小さすぎる',
    '写真がぼやける',
  ],
  upload_nice: ['半身写真','大文字', 'じこしゃしん'],
  i_konw: 'そうか',
  'Failed to load the image.': '画像の読み込みに失敗しました',
  not_empty: '空にすることはできません',
  taobao_placeholder: 'タオバオアカウント番号を入力してください',
  taobao_account: 'タオバオアカウント',
}
