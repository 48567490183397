import Compressor from 'compressorjs'
const defaultOptions = {
  checkOrientation: true,
  maxHeight: 2160,
  convertSize: 1500000,
  mimeType: 'image/jpeg',
}
function compressFile(file, options = defaultOptions) {
  return new Promise((resolve, reject) => {
    new Compressor(file, {
      ...options,
      success(result) {
        resolve(result)
      },
      error(e) {
        reject(e)
      },
    })

    //   imageCompressor.compress(file, options).then((blob) => {
    //   resolve(blob)
    // }).catch((e)=>{
    //   reject(e)
    // })
  })
}

// function compressFileOrBlobToFile(file, options =defaultOptions) {
//   return new Promise((resolve,reject) => {
//     options.checkOrientation = true
//     compressFileOrBlobToBlob(file, options).then((blob) => {
//       resolve(blobToFile(blob))
//     }).catch((e)=>{
//       reject(e)
//     })
//
//   })
//
// }

function blobToFile(blob) {
  var file = new File([blob], blob.name, {
    type: blob.type,
    lastModified: Date.now(),
  })
  return file
}
function fileToBlob(file) {
  return new Promise((resolve) => {
    var reader = new FileReader()
    reader.onload = function(e) {
      // target.result 该属性表示目标对象的DataURL
      resolve(dataURItoBlob(e.target.result))
    }
    // 传入一个参数对象即可得到基于该参数对象的文本内容
    reader.rederAsDataURL(file)
  })
}
function dataURItoBlob(dataURI) {
  var mimeString = dataURI
    .split(',')[0]
    .split(':')[1]
    .split(';')[0] // mime类型
  var byteString = atob(dataURI.split(',')[1]) //base64 解码
  var arrayBuffer = new ArrayBuffer(byteString.length) //创建缓冲数组
  var intArray = new Uint8Array(arrayBuffer) //创建视图

  for (var i = 0; i < byteString.length; i++) {
    intArray[i] = byteString.charCodeAt(i)
  }
  let blob = new Blob([intArray], { type: mimeString })
  blob.name = new Date().getTime() + '.' + mimeString.split('/')[1]
  return blob
}
export { compressFile, blobToFile, fileToBlob, dataURItoBlob }
